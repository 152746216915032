import React from "react";
import { graphql } from "gatsby";
import LandingPage from "../components/landing-page";

export const queryData = graphql`
  query {
    prismic {
      allLanding_headers(uid: "index") {
        edges {
          node {
            page_title
            page_sub_title
            page_header_cta_type
            main_image
            main_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1280, srcSetBreakpoints: [1280], quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            main_image_mobile
            main_image_mobileSharp {
              childImageSharp {
                fluid(maxWidth: 640, srcSetBreakpoints: [320, 640], quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            main_background_image
            main_background_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1800, srcSetBreakpoints: [1800], quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            section_1
            section_2
            section_3
            section_4
            section_5
          }
        }
      }
    }
  }
`;

const ContactPage = props => (
  <LandingPage {...props} />
);

export default ContactPage;
